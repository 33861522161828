import { useQuery, useSuspenseQuery } from '@tanstack/react-query';
import axios from 'axios';
import { pickBy } from 'lodash';

import { baseUrl } from '..';
import { ApiAsset } from '../../types/pairs';

export type Tokens = Record<string, ApiAsset>;

export const useGetTokensQuery = (params?: { synthetic?: boolean }) => {
  return useSuspenseQuery<Tokens>({
    queryKey: ['tokens', { synthetic: params?.synthetic }],
    // enabled: !!assets,
    refetchInterval: 60000,
    queryFn: async () => {
      const resp = await axios.get<Record<string, ApiAsset>>(`${baseUrl}/assets`, {
        params: {
          synthetic: params?.synthetic,
        },
      });
      if (params?.synthetic) {
        return pickBy(resp.data, (t) => t.is_synthetic);
      }
      return resp.data;
    },
  });
};

export type TokenValueQuery = {
  amount: number;
  value: number;
};

export const useGetTokenValueQuery = (contract_addr?: string) => {
  return useQuery<TokenValueQuery>({
    enabled: !!contract_addr,
    queryKey: [contract_addr, 'value'],
    refetchInterval: 60000,
    queryFn: async () => {
      const resp = await axios.get(`${baseUrl}/tokens/${encodeURIComponent(contract_addr ?? '')}/value`);
      return resp.data;
    },
  });
};
