import { lazy, PropsWithChildren, Suspense } from 'react';

import { Danger, ExportSquare } from 'iconsax-react';
import { FaTelegramPlane } from 'react-icons/fa';

import { LogoLoader } from 'ui/components/loader';

import { ErrorBoundary } from './errors';
import { Header } from './header';

const Modals = lazy(() => import('../modals'));

export const Layout = ({ children }: PropsWithChildren) => {
  return (
    <>
      <Header />

      <div className="animate-fade w-[calc(100%-2rem)] max-w-[1400px] flex flex-col sm:flex-row justify-between items-stretch sm:items-center gap-2 sm:gap-4 mt-4 md:mt-3 mb-4 md:mb-6 py-2 px-4 text-sm bg-[#FF223F]/60 border border-[#FF223F] text-[#FFCDD4]">
        <p className="leading-tight inline-flex items-center gap-2 flex-wrap">
          <Danger size={18} />
          The platform is in DEVNET and beta-testing!
          <a
            href="https://t.me/+o9C2c6A5D2ZiYzI0"
            target="_blank"
            rel="noreferrer"
            className="inline-flex items-center gap-1">
            Give us your feedback!
            <FaTelegramPlane />
          </a>
        </p>
      </div>

      <div className="block md:hidden w-full pt-4 px-4 mb-[-20px]">
        <img src="/assets/logo-desktop.svg" className="w-full" />
      </div>

      <main className="w-[calc(100%-2rem)] max-w-[1400px] grid grid-cols-1 gap-8 pb-10 pt-4 z-0 sm:grid-cols-2 md:grid-cols-4 lg:grid-cols-6 lg:gap-10">
        <ErrorBoundary className="col-span-full">{children}</ErrorBoundary>
      </main>
      <Suspense fallback={<LogoLoader />}>
        <Modals />
      </Suspense>
    </>
  );
};
