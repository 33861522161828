import { FC, ReactNode } from 'react';

import * as Sentry from '@sentry/react';

import { cn } from 'helpers/utils';

import { Button, Card } from 'ui/components/base';

const handleRefresh = () => {
  const timestamp = Date.now();
  window.location.href = `${window.location.pathname}?t=${timestamp}${window.location.hash}`;
};

export const ErrorBoundary: FC<{
  children: ReactNode;
  message?: string;
  errorChildren?: ReactNode;
  className?: string;
}> = ({ children, message, errorChildren, className }) => {
  return (
    <Sentry.ErrorBoundary
      fallback={({ error }) => {
        const errorMessage = error instanceof Error ? error.message : String(error);
        const isDynamicImportError =
          errorMessage.includes('Failed to fetch dynamically imported module') ||
          errorMessage.includes('Loading chunk') ||
          errorMessage.includes('Loading CSS chunk');

        return (
          <Card className={cn('p-4 h-full w-full text-white', className)}>
            {errorChildren ?? message ?? (
              <>
                <p className="text-lg text-blue font-semibold">
                  {isDynamicImportError ? 'Update Required' : 'Encountered an error:'}
                </p>
                <pre className="rounded-lg bg-white/10 p-2 font-mono text-xs whitespace-pre-wrap mt-3">
                  {isDynamicImportError
                    ? 'A new version of the application is available. Please refresh your browser to update to the latest version.'
                    : typeof error === 'string'
                      ? error
                      : (error?.toString?.() ?? null)}
                </pre>
                {isDynamicImportError && <Button onClick={handleRefresh}>Refresh</Button>}
              </>
            )}
          </Card>
        );
      }}>
      {children}
    </Sentry.ErrorBoundary>
  );
};
