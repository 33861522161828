import { useMemo } from 'react';

import { cn } from 'helpers/utils';

import { ApiToken } from 'types/pairs';

const overrides: Record<string, string> = {
  uusd: 'https://terraport.finance/assets/tokens/ust.png',
  terra1j4s969vdjgxe4uqasp9ksyhmmalgejevkdtlvm8t222zkwgqeluqsmyrdw: '/assets/tokens/silver.svg',
  terra1xsglxtxcudm9hknyquhvyl8muctrt5ksfsz9rkuma8w283wv2xfscd4cys: '/assets/tokens/gold.svg',
  terra1wq50eh6e5lr6h07ls96ydpa5tjf0trndwd4h24lsf5yh69shvkzsdak303: '/assets/tokens/goog.png',
  terra1k6493xgw5ytarvc3g9uepujc37mapw5acfxa0j829wpvddhccmqsphkl87: '/assets/tokens/amz.png',
};

type AvatarToken = Partial<Pick<ApiToken, 'name' | 'contract_addr' | 'img' | 'is_synthetic'>>;

export const TokenAvatar = ({
  className,
  token,
  secondaryToken,
}: {
  className?: string;
  token: AvatarToken;
  secondaryToken?: AvatarToken;
}) => {
  const url = useMemo(() => {
    if (overrides[token?.contract_addr || '']) {
      return overrides[token?.contract_addr || ''];
    }
    if (token?.img?.startsWith('/')) {
      return `https://terraport.finance${token?.img}`;
    }
    if (!token?.img) {
      return '/assets/tokens/fallback.webp';
    }

    return token?.img;
  }, [token?.contract_addr, token?.img]);

  return (
    <div className={cn('relative size-8', className)}>
      {token?.is_synthetic && (
        <img src="/assets/jar.svg" className="absolute inset-0 -z-10 object-cover size-full" />
      )}
      <img
        src={url}
        className={cn('rounded-full object-cover size-full aspect-square', {
          'scale-[0.7]': token?.is_synthetic,
        })}
        alt={`Image of ${token?.name}`}
      />
      {secondaryToken && <TokenAvatar token={secondaryToken} className="size-6" />}
    </div>
  );
};
