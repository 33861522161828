export const suggestChain = () =>
  (window as any).keplr
    ?.experimentalSuggestChain({
      chainId: 'rebel-2',
      chainName: 'Terra Classic Testnet',
      rpc: 'https://rpc.luncblaze.com/',
      rest: 'https://lcd.luncblaze.com/',
      stakeCurrency: {
        coinDenom: 'LUNC',
        coinMinimalDenom: 'uluna',
        coinDecimals: 6,
        coinGeckoId: 'terra-luna',
      },
      bip44: {
        coinType: 330,
      },
      bech32Config: {
        bech32PrefixAccAddr: 'terra',
        bech32PrefixAccPub: 'terra' + 'pub',
        bech32PrefixValAddr: 'terra' + 'valoper',
        bech32PrefixValPub: 'terra' + 'valoperpub',
        bech32PrefixConsAddr: 'terra' + 'valcons',
        bech32PrefixConsPub: 'terra' + 'valconspub',
      },
      currencies: [
        {
          coinDenom: 'LUNC',
          coinMinimalDenom: 'uluna',
          coinDecimals: 6,
          coinGeckoId: 'terra-luna',
        },
      ],
      feeCurrencies: [
        {
          coinDenom: 'LUNC',
          coinMinimalDenom: 'uluna',
          coinDecimals: 6,
          coinGeckoId: 'terra-luna',
          gasPriceStep: {
            low: Number('29'),
            average: Number('29'),
            high: Number('29'),
          },
        },
      ],
      features: ['ibc-transfer', 'ibc-go'],
    })
    .then((wallet: any) => {
      console.log('keplr custom', wallet);
    })
    .catch((err: any) => {
      console.error('keplr custom err', err);
    });
