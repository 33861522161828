import { useEffect } from 'react';

import { QueryClient, useQuery } from '@tanstack/react-query';
import axios from 'axios';

import { network } from '../consts/networks';
import { walletState } from '../state/wallet-state';

// export const baseUrl = import.meta.env.VITE_ENV_TESTNET
//   ? 'https://dev-api.selenium.finance'
//   : 'https://api.selenium.money';

export const baseUrl = 'https://api.selenium.money';

export const queryClient = new QueryClient();

export const loadTaxInfo = async (contract_addr: string) => {
  if (!contract_addr || contract_addr.startsWith('ibc')) {
    return '';
  }

  let taxCap = '';
  try {
    const res = (await axios.get(`${network.fcd}/terra/treasury/v1beta1/tax_caps/${contract_addr}`)).data;
    taxCap = res.tax_cap;
  } catch (error) {
    console.error(error);
  }
  return taxCap;
};

export const getSymbol = (key: string) => {
  switch (key) {
    case 'LUNC':
      return 'uluna';
    case 'USTC':
      return 'uusd';
    default:
      return '';
  }
};
