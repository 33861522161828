import { createStore } from 'zustand-x';

import { AuctionablePosition } from 'api/backend/positions';

import { ApiToken } from 'types/pairs';
import { ExtendedPosition } from 'types/selenium/mint';
import { FarmItem } from 'types/selenium/staking';

export type ModalsState = {
  mint: null | (ApiToken & { amount?: number });
  auction: AuctionablePosition | null;
  createPoll: boolean;
  govStaking: boolean;
  manageCdp: null | ExtendedPosition;
  farmStaking: null | FarmItem;
  trade: null | { from?: ApiToken; to?: ApiToken };
};

export const modals = createStore('modals')<ModalsState>({
  mint: null,
  auction: null,
  createPoll: false,
  govStaking: false,
  manageCdp: null,
  farmStaking: null,
  trade: null,
});

export const closeMintModal = () => modals.set.mint(null);
export const closeAuctionModal = () => modals.set.auction(null);
export const closeCreatePollModal = () => modals.set.createPoll(false);
export const closeGovStakingModal = () => modals.set.govStaking(false);
export const closeManageCdpModal = () => modals.set.manageCdp(null);
export const closeFarmStakingModal = () => modals.set.farmStaking(null);
export const closeTradeModal = () => modals.set.trade(null);
export const openMintModal = (param: ModalsState['mint']) => modals.set.mint(param);
export const openAuctionModal = (param: ModalsState['auction']) => modals.set.auction(param);
export const openCreatePollModal = () => modals.set.createPoll(true);
export const openGovStakingModal = () => modals.set.govStaking(true);
export const openManageCdpModal = (param: ModalsState['manageCdp']) => modals.set.manageCdp(param);
export const openFarmStakingModal = (param: ModalsState['farmStaking']) => modals.set.farmStaking(param);
export const openTradeModal = (param: ModalsState['trade']) => modals.set.trade(param);
