import { ComponentProps, ReactNode } from 'react';

import { CloseButton, Dialog, DialogBackdrop, DialogPanel, DialogProps } from '@headlessui/react';
import { Add, Danger, ExportSquare } from 'iconsax-react';
import { FaTelegramPlane } from 'react-icons/fa';

import { cn } from '../../helpers/utils';
import { Card } from './base';

export const Modal = ({
  open,
  onClose,
  className,
  children,
  title,
  aside,
  contentClassname,
  panelClassName,
  banner = null,
  showAssetRiskBanner = false,
  ...props
}: ComponentProps<typeof Card> &
  Pick<DialogProps, 'open' | 'onClose'> & {
    title?: string;
    aside?: ReactNode;
    contentClassname?: string;
    panelClassName?: string;
    banner?: ReactNode;
    showAssetRiskBanner?: boolean;
  }) => {
  return (
    <Dialog open={open} onClose={onClose} className="fixed inset-0 flex w-screen items-center justify-center">
      <DialogBackdrop className="animate-fade-in fixed inset-0 bg-[#0A4148] sm:bg-black/30 sm:backdrop-blur-xl" />
      <div className="m-auto max-h-full h-full sm:h-auto overflow-y-auto w-full sm:w-auto">
        <DialogPanel
          className={cn(
            'flex justify-center w-full sm:w-fit sm:h-fit transform transition-all flex-col gap-4 sm:gap-8 h-full',
            panelClassName,
          )}>
          {showAssetRiskBanner && (
            <div className="animate-fade w-[calc(100%-3rem)] sm:w-full flex flex-col gap-2 mx-auto mt-6 sm:mt-8 py-2 px-4 text-sm bg-[#FF223F]/60 border border-[#FF223F] text-[#FFCDD4]">
              <p className="leading-tight inline-flex items-center gap-2 flex-wrap">
                <Danger size={18} />
                The platform is in DEVNET and beta-testing!
                <a
                  href="https://t.me/+o9C2c6A5D2ZiYzI0"
                  target="_blank"
                  rel="noreferrer"
                  className="inline-flex items-center gap-1">
                  Give us your feedback!
                  <FaTelegramPlane />
                </a>
              </p>
            </div>
          )}

          <Card
            {...props}
            className={cn(
              'relative flex w-full h-full sm:h-auto sm:w-auto min-h-[300px] min-w-[300px] flex-col items-stretch justify-start p-6 sm:p-8 border-0 sm:border !bg-gradient-to-r sm:!card-bg',
              className,
            )}>
            <div className="flex w-full items-center justify-between gap-4 pb-6 lg:pb-8">
              <h2 className="text-xl font-semibold uppercase leading-none">{title}</h2>
              <div className="grow self-end border-b border-blue" />
              {aside && <div>{aside}</div>}
              <CloseButton className="rounded-full transition duration-300 ease-out hover:bg-white/10 hover:shadow-[0_0_0_5px_rgba(255,255,255,0.1)]">
                <Add className="rotate-45" size={24} />
              </CloseButton>
            </div>

            <div
              className={cn(
                'flex flex-col content-start items-start gap-2 h-full sm:h-auto',
                contentClassname,
              )}>
              {children}
            </div>
          </Card>
          {banner && <div className="hidden sm:block">{banner}</div>}
        </DialogPanel>
      </div>
    </Dialog>
  );
};

/*

  return (
        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-dvh items-center justify-center text-center">
          </div>
        </div>
      </Dialog>
    );
  };
  */
