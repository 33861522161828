import { Suspense, useEffect, useState } from 'react';

import { WalletController } from 'cosmes/wallet';
import { t } from 'i18next';
import { Copy, TrendUp } from 'iconsax-react';
import { capitalize, map } from 'lodash';
import { FaMobileScreen, FaPuzzlePiece } from 'react-icons/fa6';
import { toast } from 'react-toastify';

import { useGetTokensQuery } from 'api/backend/tokens';
import { useBalances } from 'api/rpc/balance';

import { openMintModal } from 'state/modals';

import { FormattedNumber, FormattedPercent } from 'ui/components/formatted-number';
import { LogoLoader } from 'ui/components/loader';
import { TokenAvatar } from 'ui/components/token-avatar';

import { network } from '../../consts/networks';
import { convertMicroDenomToDenom, copyToClipboard } from '../../helpers/utils';
import {
  closeWalletConnectModal,
  connectWallet,
  controllers,
  openWalletConnectModal,
  useAddress,
  walletState,
} from '../../state/wallet-state';
import { Button } from '../components/base';
import { Modal } from '../components/modal';

const SynthList = () => {
  const { data: synthetics } = useGetTokensQuery({ synthetic: true });
  const { data: balances } = useBalances(synthetics);
  return (
    <div className="grid grid-cols-1 sm:grid-cols-2 pb-8 gap-8 w-full max-w-[600px] self-center">
      {map(synthetics, (synth) => (
        <div
          key={synth.contract_addr}
          className="flex w-full flex-row items-center justify-between gap-2 px-4 py-3">
          <div className="flex flex-row items-center gap-4">
            <TokenAvatar token={synth} />
            <div>
              <p className="mb-1 font-semibold text-blue">{synth.name}</p>
              <p className="flex items-center gap-1 text-xs">
                <TrendUp size={12} /> <FormattedPercent value={synth.oracle.delta_24h * 100} />
              </p>
            </div>
          </div>
          {balances?.[synth.contract_addr] ? (
            <div className="font-medium text-blue items-end flex flex-col">
              <p className="text-xs">{synth.denom} Balance</p>
              <FormattedNumber value={convertMicroDenomToDenom(balances?.[synth.contract_addr])} />
            </div>
          ) : (
            <div className="flex flex-col gap-1 font-medium text-blue">
              <p className="text-xs">Balance</p>
              <Button className="rounded px-2 py-1 text-xs" onClick={() => openMintModal(synth)}>
                MINT NOW!
              </Button>
            </div>
          )}
        </div>
      ))}
    </div>
  );
};

const CurrentWallet = () => {
  const wallet = walletState.use.wallet();
  const controller = walletState.use.currentController();

  return wallet ? (
    <div className="flex w-full flex-col items-start md:w-[700px] lg:w-[800px]">
      <p className="mb-4 font-medium">All Available Synthetic Assets</p>
      <Suspense fallback={<LogoLoader />}>
        <SynthList />
      </Suspense>
      <Button
        big
        className="w-full p-3 text-base"
        onClick={() => {
          controller?.disconnect([network.id]);
        }}>
        Disconnect
      </Button>
    </div>
  ) : null;
};

const ControllerSection = ({ controller }: { controller: WalletController }) => {
  const [extensionInstalled, setExtensionInstalled] = useState(false);
  useEffect(() => {
    controller.isInstalled('extension').then(setExtensionInstalled);
  }, [controller]);
  const [mobileInstalled, setMobileInstalled] = useState(false);
  useEffect(() => {
    controller.isInstalled('walletconnect').then(setMobileInstalled);
  }, [controller]);
  return (
    <>
      {extensionInstalled && (
        <Button
          className="flex w-28 flex-col items-center justify-evenly gap-2 rounded-xl py-4"
          onClick={() => connectWallet(controller.id, 'extension')}>
          <FaPuzzlePiece className="size-8" />
          <p className="font-bold">{capitalize(controller.id)}</p>
          <p className="text-xs">Extension</p>
        </Button>
      )}
      {mobileInstalled && (
        <Button
          className="flex w-28 flex-col items-center justify-evenly gap-2 rounded-xl py-4"
          onClick={() => connectWallet(controller.id, 'walletconnect')}>
          <FaMobileScreen className="size-8" />
          <p className="font-bold">{capitalize(controller.id)}</p>
          <p className="text-xs">Mobile App</p>
        </Button>
      )}
    </>
  );
};

export const WalletButton = () => {
  const address = useAddress();
  const open = walletState.use.connectModalIsOpen();
  const isDemo = walletState.use.demo();
  return (
    <div className="flex flex-row gap-2">
      <Button onClick={() => walletState.set.demo(!isDemo)}>Demo Mode: {isDemo ? 'ON' : 'OFF'}</Button>
      <Button className="connect-wallet" onClick={openWalletConnectModal}>
        {address ? t('head.connected') : t('head.connect')}
      </Button>
      <Modal
        open={open}
        onClose={() => closeWalletConnectModal()}
        title="Wallet"
        aside={
          address && (
            <div className="flex w-full min-w-0 max-w-48 flex-row items-center justify-between">
              <span className="min-w-0 max-w-[200px] overflow-hidden text-ellipsis text-nowrap">
                {address.slice(0, -4)}
              </span>
              <span>{address.slice(-4)}</span>
              <button
                className="ml-2 rounded bg-transparent p-0.5 transition focus:bg-blue/10"
                onClick={() =>
                  copyToClipboard(address ?? '')?.then(() => toast.success('Copied to clipboard'))
                }>
                <Copy variant="Bold" size={16} />
              </button>
            </div>
          )
        }>
        {address ? (
          <CurrentWallet />
        ) : (
          <>
            <h2>Connect your wallet:</h2>
            <div className="flex flex-wrap gap-6">
              {Object.values(controllers).map((controller) => (
                <ControllerSection key={controller.id} controller={controller} />
              ))}
            </div>
          </>
        )}
      </Modal>
    </div>
  );
};
