export const NATIVE_TOKEN_DECIMALS = 6;
export const LP_TOKEN_DECIMALS = 6;
export const COMMISSION_RATE = 0.02; // 2%
export const network = {
  name: 'testnet',
  chainID: 'rebel-2',
  rpc: 'https://rpc.luncblaze.com',
  fcd: 'https://lcd.luncblaze.com',
  explorer: 'https://wallet.terra-classic.io/explorer/',
  id: 'rebel-2',
  gasPrices: { uusd: 0.15 },
  fee: { gasPrice: 0.15, amount: 100000 }, // 0.1 USTC
  adminContract: 'terra1ndy9h4dygkdg7sdmpck9eajsfe24zx5f56hhfuymssr88mkzljlq47x49y',
  priceOracleContract: 'terra1sqdshg6u5ahp27uj0vn5rwsarjzhadfteye4hsmue9a7ekqsm4pqfv46mh',
  collateralOracleContract: 'terra1wk93l5nvuvps2qzsm0584gjzn9emr8ncupqupg2l0n8w4lpp93pqkawk0c',
  farmingContract: 'terra1he3xgzwm7646d0lfwtlzngakm8x6w9glnp2p2zp0r3d5z87ddxtqzethgd',
  governanceContract: 'terra1hulegssgtgv5fw6a7wupd88lkfsuafwj30c0mphldc304r2hncvsfgzr2l',
  mintContract: 'terra12940253mxuhkv7f2egh8kxm9khwpf9hqnnhvzqvf0pdawv77f3cs039tyf',
  collectorContract: 'terra1v2sr68cjc8rcg3gp3qwpzm3jk2m5g7j3u7y7rny6zp3cdu62nc0qln89ku',
  seleniumTokenContract: 'terra1sarathmvhaq0ukx2p0hsynf0qja00s4thr8s8ceha28s30kcvkjszmz58a',
  terraportRouterContract: 'terra1l4fuw8ypsydm73p36qrclygtwehjpjucx0h588qemgamqmswt96sjpfvxp',
  owner: 'terra1k6ywt3f2x75euyu68u9efmnrrs80gsjln6ar2f',
  factoryContract: 'terra1xze97yghkwpn9z7gt3yedt83hafaxz9pp2twx4e2upg96ync2w5qg8d4yj',
};
// : {
//     name: 'classic',
//     chainID: 'columbus-5',
//     rpc: 'https://terra-classic-rpc.publicnode.com',
//     fcd: 'https://terra-classic-fcd.publicnode.com',
//     explorer: 'https://finder.terraport.finance/finder/',
//     id: 'columbus-5',
//     gasPrices: { uusd: 0.15 },
//     fee: { gasPrice: 0.15, amount: 100000 }, // 0.1 USTC
//     adminContract: 'NP',
//     priceOracleContract: 'NP',
//     collateralOracleContract: 'NP',
//     farmingContract: 'NP',
//     governanceContract: 'NP',
//     mintContract: 'NP',
//     collectorContract: 'NP',
//     seleniumTokenContract: 'NP',
//     terraportRouterContract: 'NP',
//   };
