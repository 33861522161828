import { useQuery } from '@tanstack/react-query';
import { RpcClient, getCw20Balance, getNativeBalances } from 'cosmes/client';
import { utf8 } from 'cosmes/codec';
import { CosmwasmWasmV1QuerySmartContractStateService } from 'cosmes/protobufs';
import { find, forEach, isEmpty, map, reduce } from 'lodash';

import { TERRA } from '../../consts/common-tokens';
import { network } from '../../consts/networks';
import { convertMicroDenomToDenom } from '../../helpers/utils';
import { useAddress } from '../../state/wallet-state';
import { ApiAsset } from '../../types/pairs';

export const useBalance = (token: ApiAsset | null) => {
  const address = useAddress();

  const { data } = useQuery({
    enabled: !!(address && token?.contract_addr),
    queryFn: async () => {
      if (token && token.contract_addr.startsWith('terra')) {
        const amount = await getCw20Balance(network.rpc, {
          address: address!, //checked by the enable flag
          token: token.contract_addr,
        });
        return amount.toString();
      }
      const coins = await getNativeBalances(network.rpc, {
        address: address!,
      });
      return find(coins, ({ denom }) => denom === token?.microdenom)?.amount;
    },
    queryKey: ['balance', token?.contract_addr],
  });
  return data ? convertMicroDenomToDenom(data, token?.decimals) : 0;
};

export const useCW20Balance = (contract_addr: string | null) => {
  const address = useAddress();

  const { data } = useQuery({
    enabled: !!(address && contract_addr),
    queryFn: async () => {
      if (contract_addr) {
        const amount = await getCw20Balance(network.rpc, {
          address: address!, //checked by the enable flag
          token: contract_addr,
        });
        return amount.toString();
      }
    },
    queryKey: ['balance', contract_addr],
  });
  return data ? convertMicroDenomToDenom(data) : 0;
};

export const useTerraBalance = () => {
  return useCW20Balance(TERRA.contract_addr);
};

export const useBalances = (tokens: ApiAsset[] | { [s: string]: ApiAsset }) => {
  const address = useAddress();
  return useQuery({
    enabled: !!address && !isEmpty(tokens),
    queryKey: ['balance', map(tokens, (t: ApiAsset) => t.contract_addr)],
    queryFn: async () => {
      if (address) {
        const batch = RpcClient.newBatchQuery(network.rpc);
        const responses: Record<string, number> = {};
        forEach(Object.values(tokens), (t: ApiAsset): void => {
          if (t.type !== 'native') {
            batch.add(
              CosmwasmWasmV1QuerySmartContractStateService,
              {
                address: t.contract_addr,
                queryData: utf8.decode(JSON.stringify({ balance: { address: address } })),
              },
              (error, response) => {
                responses[t.contract_addr] = Number(JSON.parse(atob(response?.toJson().data ?? '')).balance);
              },
            );
          }
        });
        await batch.send();
        const nativeBalances = await getNativeBalances(network.rpc, { address });
        return reduce(nativeBalances, (acc, val) => ({ ...acc, [val.denom]: Number(val.amount) }), responses);
      } else {
        return {};
      }
    },
  });
};
