import { InvalidateQueryFilters } from '@tanstack/react-query';
import { clsx, type ClassValue } from 'clsx';
import { forEach, isEmpty } from 'lodash';
import { twMerge } from 'tailwind-merge';

import { ApiToken } from 'types/pairs';
import { AssetInfo, NativeToken, CW20Token } from 'types/selenium';

import { queryClient } from '../api';

export function convertMicroDenomToDenom(amount: string | number, decimal = 6) {
  if (typeof amount === 'string') {
    amount = Number(amount);
  }
  amount = amount / 10 ** decimal;
  return isNaN(amount) ? 0 : amount;
}

export function convertDenomToMicroDenom(amount: string | number, decimal = 6) {
  if (typeof amount === 'string') {
    amount = Number(amount);
  }
  amount = amount * 10 ** decimal;
  return isNaN(amount) ? '0' : String(amount).split('.')[0];
}

export const parseErrorMsg = (errMsg: string) => {
  if (isEmpty(errMsg)) return '';
  let returStr = '';
  const startPos = JSON.stringify(errMsg).search('submessages:');
  if (startPos >= 0) {
    const subStr = errMsg.substring(startPos + 12, errMsg.length);
    returStr = subStr;
  } else returStr = errMsg;
  return returStr;
};

export const hasTaxToken = (contract_addr: string) =>
  !contract_addr.startsWith('terra') && !contract_addr.startsWith('ibc');

export const copyToClipboard = (data: string) => {
  if (navigator.clipboard && window.isSecureContext) {
    // navigator clipboard api method'
    return navigator.clipboard.writeText(data);
  } else {
    const textField = document.createElement('textarea');
    textField.innerText = data;
    textField.style.position = 'fixed';
    textField.style.left = '-999999px';
    textField.style.top = '-999999px';
    document.body.appendChild(textField);
    textField.select();
    document.execCommand('copy');
    textField.remove();
    return Promise.resolve();
  }
};

export const formatNumbers = (num: number, decimals = 2) => {
  return Intl.NumberFormat(navigator.languages, {
    maximumFractionDigits: decimals,
  }).format(Number((num ?? 0).toFixed(decimals)));
};

export const formatPositiveInteger = (value: number) => {
  let result = Math.trunc(value);
  if (result <= 0) result = 0; //il trunc potrebbe restituire -0
  return Intl.NumberFormat(navigator.languages, {
    minimumIntegerDigits: 2,
  }).format(result);
};

export const cn = (...inputs: ClassValue[]) => {
  return twMerge(clsx(inputs));
};

export const invalidateQueries = (keys: InvalidateQueryFilters[]) =>
  setTimeout(() => forEach(keys, (key) => queryClient.invalidateQueries(key)), 20);

export const getInfo = (token: ApiToken): AssetInfo =>
  token.contract_addr.startsWith('terra')
    ? {
        token: {
          contract_addr: token.contract_addr,
        },
      }
    : {
        native_token: {
          denom: token.contract_addr,
        },
      };

export const getAddressFromInfo = (info: AssetInfo): string =>
  info.native_token ? (info as NativeToken).native_token.denom : (info as CW20Token).token.contract_addr;

export const getInfoFromAddress = (address: string): AssetInfo =>
  address.startsWith('terra') ? { token: { contract_addr: address } } : { native_token: { denom: address } };

export const getDeltaPercent = (today: string, yesterday: string) => {
  const nT = Number(today);
  const nY = Number(yesterday);
  return ((nT - nY) / nT) * 100;
};
