import { ComponentProps, FC, ReactNode, useMemo } from 'react';

import { useTailwindMD } from '../../helpers/responsive';
import { cn } from '../../helpers/utils';

// export const roundToDecimals = (n: number, decimals: number = 2) => {
//   const log10 = n ? Math.floor(Math.log10(n)) : 0;
//   const div =
//     log10 < 0 ? Math.pow(10, decimals - log10 - 1) : Math.pow(10, decimals);
//   return Math.round(n * div) / div;
// };

const intl = new Intl.NumberFormat(navigator.languages, {
  // minimumSignificantDigits: 2,
  minimumFractionDigits: 0,
  maximumFractionDigits: 2,
});

const fractionalIntl = new Intl.NumberFormat(navigator.languages, {
  // minimumSignificantDigits: 2,
  maximumSignificantDigits: 5,
});

const mobileFractionalIntl = new Intl.NumberFormat(navigator.languages, {
  // minimumSignificantDigits: 2,
  maximumSignificantDigits: 2,
});

export const FormattedNumber: FC<
  Omit<ComponentProps<'span'>, 'prefix'> & {
    subClassName?: string;
    extended?: boolean;
    value: number | null | undefined;
    prefix?: ReactNode;
    suffix?: ReactNode;
    signed?: boolean;
  }
> = ({
  subClassName = '',
  extended = false,
  value = null,
  prefix = '',
  suffix = '',
  className,
  signed = false,
  ...rest
}) => {
  const isDesktop = useTailwindMD();
  const formatted = useMemo(() => {
    if (value === null || value === undefined || isNaN(value)) return '-';
    if (value === 0) return '0';

    if (!isDesktop && !extended) {
      // Handle billions by putting a B at the end
      if (value > 1000000000) {
        return `${intl.format(Number((value / 1000000000).toFixed(2)))} B`;
      }
      // Handle millions by putting an M at the end
      if (value > 1000000) {
        return `${intl.format(Number((value / 1000000).toFixed(2)))} M`;
      }
      // Handle thousands by putting an M at the end
      if (value > 1000) {
        return `${intl.format(Number((value / 1000).toFixed(2)))} k`;
      }
      if (value < 1) {
        const intled = mobileFractionalIntl.format(value);
        const zeros = intled.substring(2).match(/0+/)?.[0];
        if (zeros && zeros.length > 2) {
          const [pre, post] = intled.split(zeros);
          return (
            <>
              {pre}0
              <sub className={cn('relative bottom-1 self-end text-[70%]', subClassName)}>{zeros.length}</sub>
              {post}
            </>
          );
        }
        return intled;
      }
    }
    // Handle number smaller than 0.0001
    if (value < 1) {
      const intled = fractionalIntl.format(value);
      const zeros = intled.substring(2).match(/0+/)?.[0];
      if (zeros && zeros.length > 2) {
        const [pre, post] = intled.split(zeros);
        return (
          <>
            {pre}0
            <sub className={cn('relative bottom-1 self-end text-[70%]', subClassName)}>{zeros.length}</sub>
            {post}
          </>
        );
      }
      return intled;
    }

    // Handle the rest of the numbers by falling back to the default behavior
    return intl.format(Number(value));
  }, [value, subClassName, extended, isDesktop]);
  return (
    <span
      style={{ flexFlow: 'nowrap' }}
      className={cn('inline-flex h-fit min-w-0 flex-nowrap items-center', className)}
      {...rest}>
      {prefix} {signed && Number(value) >= 0 && '+'}
      {formatted} {suffix}
    </span>
  );
};

const percentFormatter = new Intl.NumberFormat(navigator.languages, {
  // minimumSignificantDigits: 2,
  minimumFractionDigits: 0,
  maximumFractionDigits: 2,
});

export const FormattedPercent: FC<
  ComponentProps<'span'> & {
    value: number | null | undefined;
    prefix?: ReactNode;
    suffix?: ReactNode;
    signed?: boolean;
    threshold?: number;
  }
> = ({ value = null, prefix = '', suffix = '', className, signed, threshold, ...rest }) => {
  const formatted = useMemo(() => {
    if (value === null || value === undefined) return '0';
    if (value === 0) return '0';

    // Handle the rest of the numbers by falling back to the default behavior
    return percentFormatter.format(Number(value));
  }, [value]);
  return (
    <span
      className={cn(
        'flex h-fit items-center',
        typeof threshold !== 'undefined' && Number(value) > threshold && 'text-green',
        typeof threshold !== 'undefined' && Number(value) < threshold && 'text-red',
        className,
      )}
      {...rest}>
      {prefix} {signed && Number(value) >= 0 && '+'}
      {formatted} % {suffix}
    </span>
  );
};
