import { lazy, Suspense } from 'react';

import ReactDOM from 'react-dom';
import { Navigate, Route, Routes } from 'react-router';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { useGetHealthQuery } from 'api/backend/base';

import { LogoLoader } from 'ui/components/loader';

import { Layout } from './ui/layout';

const Farming = lazy(() => import('./ui/pages/farming'));
const Mint = lazy(() => import('./ui/pages/mint'));
const Auction = lazy(() => import('./ui/pages/auction'));
const Dashboard = lazy(() => import('./ui/pages/dashboard'));
const Analytics = lazy(() => import('./ui/pages/analytics'));
const Governance = lazy(() => import('./ui/pages/governance'));
const Maintenance = lazy(() => import('./ui/pages/maintenance'));

const toastRoot = document.getElementById('toast-root') as HTMLElement;
const ToastPortal = () => {
  return ReactDOM.createPortal(
    <ToastContainer
      toastClassName="box bg-dark/40 border-blue border rounded-none text-blue backdrop-blur-md"
      position="bottom-right"
      autoClose={5000}
      newestOnTop={true}
      closeOnClick
      rtl={false}
      pauseOnFocusLoss={false}
      draggable
      pauseOnHover={true}
      stacked={false}
      progressClassName="!bg-none"
    />,
    toastRoot,
  );
};

const App = () => {
  const { data } = useGetHealthQuery();

  return (
    <>
      <Layout>
        <Suspense fallback={<LogoLoader />}>
          {data.status !== 'ok' ? (
            <Maintenance />
          ) : (
            <Routes>
              <Route path="/" element={<Navigate to="/dashboard" />} />
              <Route path="/dashboard/:mode?" element={<Dashboard />} />
              <Route path="/mint" element={<Mint />} />
              <Route path="/auction" element={<Auction />} />
              <Route path="/analytics" element={<Analytics />} />
              <Route path="/farm" element={<Farming />} />
              <Route path="/governance/*" element={<Governance />} />
            </Routes>
          )}
        </Suspense>
      </Layout>
      <ToastPortal />
    </>
  );
};

export default App;
