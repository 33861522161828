import { useEffect, useState } from 'react';

import { Dialog, DialogPanel } from '@headlessui/react';
import { t } from 'i18next';
import { Link, useLocation } from 'react-router-dom';

import { useTailwindMD } from 'helpers/responsive';

import { cn } from '../../helpers/utils';
import { WalletButton } from './wallet';

const links = [
  { url: '/dashboard', label: 'head.dashboard' },
  { url: '/mint', label: 'head.mint' },
  { url: '/analytics', label: 'head.analytics' },
  { url: '/auction', label: 'head.auction' },
  { url: '/farm', label: 'head.farm' },
  { url: '/governance', label: 'head.governance' },
];

const DesktopHeader = () => {
  const location = useLocation();
  return (
    <header className="w-full sm:w-[calc(100%-2rem)] sm:max-w-[1400px] flex items-center justify-between pb-3 pt-4">
      <Link to="/" id="logo" className="flex content-center items-center transition-all">
        <img src="/assets/logo-desktop.svg" className="max-w-1/3 hidden lg:flex" />
        <img src="/assets/logo-mobile.svg" className="flex lg:hidden" />
      </Link>
      <nav className="grid grid-cols-6 place-items-stretch gap-2 p-6 font-advent font-extrabold uppercase leading-5">
        {links.map((link) => (
          <Link key={link.url} to={link.url} className="group flex items-center justify-center text-center">
            <span
              className={cn(
                'border-b-4 border-solid border-transparent px-2.5 transition-all',
                location.pathname.startsWith(link.url) ? 'border-blue' : 'group-hover:border-blue/60',
              )}>
              {t(link.label)}
            </span>
          </Link>
        ))}
      </nav>
      <WalletButton />
    </header>
  );
};

const MobileHeader = () => {
  const [open, setOpen] = useState(false);
  const location = useLocation();

  return (
    <header
      data-open={open}
      className={cn(
        'group w-full sticky top-0 z-[1] flex items-center justify-between p-4 w-full',
        'bg-gradient-to-b from-[#0a1927e0] from-20% via-[#0a1927a3] via-50% to-transparent',
      )}>
      <WalletButton />

      <div>
        <button className="flex flex-col gap-2 w-8" onClick={() => setOpen((s) => !s)}>
          <span className="w-full h-[2px] bg-blue rounded duration-300 group-data-[open=true]:rotate-45 group-data-[open=true]:translate-y-[10px]" />
          <span className="w-full h-[2px] bg-blue rounded duration-300 group-data-[open=true]:opacity-0 group-data-[open=true]:scale-0" />
          <span className="w-full h-[2px] bg-blue rounded duration-300 group-data-[open=true]:-rotate-45 group-data-[open=true]:translate-y-[-8px]" />
        </button>
      </div>

      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        transition
        className="fixed inset-0 flex w-screen items-center justify-center bg-[#0A4148]">
        <div className="m-auto overflow-y-auto w-full max-h-full">
          <DialogPanel className="flex justify-center transform transition-all">
            <nav className="flex flex-col gap-8 p-6 font-advent font-extrabold uppercase leading-5">
              {links.map((link) => (
                <Link
                  key={link.url}
                  to={link.url}
                  onClick={() => setOpen(false)}
                  className="group flex items-center justify-center text-center">
                  <span
                    className={cn(
                      'border-b-4 border-solid border-transparent px-2.5 transition-all',
                      location.pathname.startsWith(link.url) ? 'border-blue' : 'group-hover:border-blue/60',
                    )}>
                    {t(link.label)}
                  </span>
                </Link>
              ))}
            </nav>
          </DialogPanel>
        </div>
      </Dialog>
    </header>
  );
};

export const Header = () => {
  const isMD = useTailwindMD();
  return isMD ? <DesktopHeader /> : <MobileHeader />;
};
