import { cn } from 'helpers/utils';

export const LogoLoader = ({ className }: { className?: string }) => (
  <img
    src={'/assets/logo.gif'}
    className={cn(
      'size-16 place-self-center justify-self-center self-center col-span-full animate-fade',
      className,
    )}
  />
);
